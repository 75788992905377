//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import "swiper/dist/css/swiper.css";
import { Cookies, Loading, QSpinner, Platform, Meta } from "quasar";
import meta from "@/mixins/meta";
import getTheme from "@/mixins/getTheme";
export default {
  name: 'App',
  mixins: [meta,getTheme],
  data() {
    return {
      loaded: false,
      key_partners: process.env.KEY_PARTNERS,
      defaultLogo: process.env.DEFAULT_LOGO_URL,
    };
  },
  beforeMount() {
    Loading.setDefaults({
      spinner: QSpinner,
      /*message: 'Please Wait...',
      messageColor: 'white',*/
      spinnerSize: 50, // in pixels
      spinnerColor: "secondary"
    });
    this.loading_render(this);

  },
  mounted() {
    if (this.$route.query.key != this.key_partners) {
      // window.open('https://www.visionplus.id/');
      window.location.href = 'https://www.visionplus.id/'
    }
    this.loaded = true;
  },
  watch: {
    $route: function(to, from) {
      this.loading_render(this);
      let data;
      if (to.name == "details") {
        data = {
          name: to.name,
          id: to.params.id
        };
        this.$store.dispatch("getData", data);
      } else {
        data = {
          name: to.name
        };
        this.$store.dispatch("getData", data);
      }
      if (this.$route.query.key != this.key_partners) {
        window.open('https://www.visionplus.id/', '_blank');
      }
    }
  },
  async preFetch({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    // fetch data, validate route and optionally redirect to some other route...
    const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies;
    const platform = process.env.SERVER
      ? Platform.parseSSR(ssrContext)
      : Platform;
    /* const parse_before_render = {
      isLogin : cookies.has('isLogin') ? cookies.get('isLogin') : false,
      isPayTv : cookies.has('isPayTv') ? cookies.get('isPayTv') : 'no',
      profile : cookies.has('profile') ? cookies.get('profile') : null
    }*/

    if (cookies.has("isLogin")) {
      store.dispatch("set_auth", cookies.get("isLogin"));
    }
    if (cookies.has("isPayTv")) {
      store.dispatch("set_paytv", cookies.get("isPayTv"));
    }
    if (cookies.has("profile")) {
      store.dispatch("set_profile", cookies.get("profile"));
    }
    if (cookies.has("bundles")) {
      store.dispatch("set_bundles", cookies.get("bundles"));
    }
    if (cookies.has("loginWith")) {
      store.dispatch("set_loginwith", cookies.get("loginWith"));
    }
    if (cookies.has("browser")) {
      store.dispatch("set_browser", cookies.get("browser"));
    }

    store.dispatch("set_browser", platform.is.name);
    store.dispatch("set_ios_device", platform.is.ios);
  },
  methods: {
    loading_render(vm) {
      vm.$q.loading.show();
      vm.$nextTick(function() {
        // => 'DOM loaded and ready'
        setTimeout(function() {
          vm.$q.loading.hide();
        }, 1500);
      });
    }
  }
}
