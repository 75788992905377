import Vue from 'vue'
import axios from 'axios'
import { Cookies, Dialog } from 'quasar'
import CryptoJS from 'crypto-js';

export default ({ Vue, store, ssrContext }) => {
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies

  if (cookies.get('lang') == null) {
    cookies.set('lang','en-us',{path: '/'})
  }

  let header

  let platform = "web";

  let isOffline = false

  async function supportsWebp() {
    if (!self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
  }

  function encryptData(params) {
    if (params) {
      return CryptoJS.AES.encrypt(params, process.env.KEYCRYP).toString() 
    } else {
      return null
    }
  }
  
  function decryptData(params){
    if (params) {
      var code = CryptoJS.AES.decrypt(params, process.env.KEYCRYP);
      var decryptedMessage = code.toString(CryptoJS.enc.Utf8);
      return decryptedMessage; 
    } else {
      return null
    }
  }

  let setSupportWebp = false
  if (cookies.get('webpSupported') == null) {
    (async () => {
      if (cookies.get('webpSupported') == null) {
        if(await supportsWebp()) {
         cookies.set('webpSupported',true,{path: '/'})
          setSupportWebp=true
        }
        else {
          cookies.set('webpSupported',false,{path: '/'})
          setSupportWebp=false
        }
      }
    });
  } else {
    setSupportWebp = cookies.get('webpSupported')
  }


  if (cookies.get('lang')=='en-us'){
    header = {
      'Content-Type':'application/json',
      'Accept-Language' : 'en',
      'Authorization' : process.env.AUTHORIZATION,
    }
  }else{
    header = {
      'Content-Type':'application/json',
      'Accept-Language' : 'id',
      'Authorization' : process.env.AUTHORIZATION
    }
  }


  Vue.prototype.$axios = axios


  const instance = axios.create({
    headers: header
  })

  instance.defaults.headers.common['Authorization'] = process.env.AUTHORIZATION;
  instance.defaults.headers.post['Content-Type'] = 'application/json';
  // instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  // instance.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
  // instance.defaults.headers.common['Access-Control-Allow-Headers'] = '*';
  // instance.defaults.headers.common['X-CLIENT-ID'] = process.env.X_CLIENT_ID;
  // instance.defaults.headers.common['X-SECRET-KEY'] = process.env.X_SECRET_KEY;

  /*axios.defaults.headers.common['Authorization'] = cookies.get('tokenApi');
  axios.defaults.headers.post['Content-Type'] = 'application/json';*/

  // interceptors request for has Token ?

  instance.interceptors.request.use(async function
    (config){
      // WebP server-side content negotiation via Accept header
      // if (setSupportWebp) {
      //   const accept = config.headers.common.Accept.split(', ')
      //   accept.splice(accept.length - 1, 0, 'image/webp')
      //   config.headers.common.Accept = accept.join(', ')
      // }
      // const hastoken = cookies.has('_tnap');
      // const token = decryptData(cookies.get('_tnap'));
      // const auth = cookies.has('isLogin');
      
      // if(auth){
      //   store.dispatch('set_auth',cookies.get('isLogin'))
      // }
      // if(token == undefined && !hastoken){
      //   let visitor = await getVisitor(config,store,cookies)
      //   return visitor;
      // }else{
      config.headers['Authorization'] = process.env.AUTHORIZATION;
      return config;
      // }
    },
    (err) => {
      return err 
      // Promise.reject(err)
    }
  )

  // interceptor for expired token
  instance.interceptors.response.use((responseApi) => {
    // if (responseApi.data.status.code == 1017){
    //   return new Promise((resolve, reject) => {
    //     console.log("expired");
    //     instance.get(process.env.API+'/api/v1/refreshtoken')
    //     .then(response => {
    //       cookies.remove('_tnap')
    //       cookies.set('_tnap',encryptData(response.data.data['access_token']),{path: '/'})

    //       // delete instance.defaults.headers.common['Authorization'];
    //       instance.defaults.headers.common['Authorization'] = response.data.data['access_token'];

    //       responseApi.config._retry = true;
    //       resolve(instance(responseApi.config))
    //     })
    //     .catch(e => {
    //       console.log('error login', e)
    //       reject(e)
    //     })
    //   });
    // } else if (responseApi.data.status.code == 1015){
    //   return new Promise((resolve, reject) => {
    //     console.log('invalid');
    //     let data = {
    //       "device_id": "8dcbff27b7cdfc824d98d69881b8d130",
    //       "password": "87563cc84e75d309234bcadeqw34612a",
    //       "platform": "web",
    //       "username": "d3a0774711fcbba9ce85e7f0a87d6748@gmail.com"
    //     }

    //     instance.post(process.env.API+'/api/v/login', data)
    //     .then(success=>{

    //       cookies.remove('_tnap')
    //       cookies.set('_tnap',encryptData(success.data.data['access_token']),{path: '/'})
    //       cookies.remove('uid')
    //       cookies.set('uid', success.data.data.user.id, {path: '/'});

    //       // delete instance.defaults.headers.common['Authorization'];
    //       instance.defaults.headers.common['Authorization'] = success.data.data['access_token'];

    //       store.dispatch('set_token', encryptData(success.data.data['access_token']))
    //       store.commit('SET_UID', success.data.data.user.id);

    //       // console.log('responseApi.config', responseApi.config);
    //       responseApi.config._retry = true;
    //       resolve(instance(responseApi.config))
    //     })
    //     .catch(e => {
    //       console.log('error login', e)
    //       reject(e)
    //     })
    //   });
    // } else if (responseApi.data.status.code == 1013){
    //   return new Promise((resolve, reject) => {
    //     let data = {
    //       "device_id": "8dcbff27b7cdfc824d98d69881b8d130",
    //       "password": "87563cc84e75d309234bcadeqw34612a",
    //       "platform": "web",
    //       "username": "d3a0774711fcbba9ce85e7f0a87d6748@gmail.com"
    //     }

    //     instance.post(process.env.API+'/api/v/login', data)
    //     .then(success=>{

    //       cookies.remove('_tnap')
    //       cookies.set('_tnap',encryptData(success.data.data['access_token']),{path: '/'})
    //       cookies.remove('uid')
    //       cookies.set('uid', success.data.data.user.id, {path: '/'});

    //       // delete instance.defaults.headers.common['Authorization'];
    //       instance.defaults.headers.common['Authorization'] = success.data.data['access_token'];

    //       store.dispatch('set_token', encryptData(success.data.data['access_token']))
    //       store.commit('SET_UID', success.data.data.user.id);

    //       // console.log('responseApi.config', responseApi.config);
    //       responseApi.config._retry = true;
    //       resolve(instance(responseApi.config))
    //     })
    //     .catch(e => {
    //       console.log('error login', e)
    //       reject(e)
    //     })
    //   });
    // }else{
      return responseApi
    // }
  },
  function (error) {
      console.log(error);
     // return Error object with Promise
    //  return Promise.reject(error);
    return error.response;
  });

  
  async function getVisitor(config,store,cookies){
    try {
      // function uuidv4() {
      //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      //     var r = Math.random() * 16 | 0,
      //       v = c == 'x' ? r : (r & 0x3 | 0x8);
      //     return v.toString(16);
      //   });
      // }
      // if (cookies.has('device_id')) {
      //   cookies.remove('device_id')
      // }
      // cookies.set('device_id', uuidv4());
      // let dvId = cookies.get('device_id')
      
      let data = {
        "device_id": "8dcbff27b7cdfc824d98d69881b8d130",
        "password": "87563cc84e75d309234bcadeqw34612a",
        "platform": "web",
        "username": "d3a0774711fcbba9ce85e7f0a87d6748@gmail.com"
      }
      // let responseToken = await axios.post(process.env.API+'/api/v/login', data)
      // .then(success=>{
      //   config.headers.Authorization = success.data.data.access_token;
      //   store.dispatch('set_token', encryptData(success.data.data.access_token))
      //   store.dispatch('set_auth',false)
      //   cookies.set('_tnap', encryptData(success.data.data.access_token),{path: '/'});
      //   cookies.set('isLogin',false,{path: '/'});
      //   store.dispatch('set_paytv', 'no')
      //   Cookies.set('isPayTv','no',{path: '/'})
      //   if (cookies.has('uid')) {
      //     cookies.remove('uid')
      //   }
      //   cookies.set('uid', success.data.data.user.id, {
      //     path: '/'
      //   });
      //   store.commit('SET_UID', success.data.data.user.id);
      //   return config;
      // })
      return responseToken;
    }catch (error) {
      /*Notify.create({
        type: "negative",
        message: "Connection unstabil. Please check your connection",
        position: "top"
      })*/
      console.error(error);
    }
  }
  async function apiGet(endpoint) {
    try {
      let responseApi = await instance.get(process.env.API+endpoint);
      return responseApi
    }catch (error) {
      if (!navigator.onLine) {
        if (!isOffline) {
          isOffline = true
          Dialog.create({
              title:'',
              message: "Connection unstabil. Please check your connection",
              dark: true,
              persistent: true
          }).onDismiss(() => {
            isOffline = false
          })
        }
      }
      console.error(error);
    }
  }
  async function apiDelete(endpoint) {
    try {
      /*console.log(axios.defaults.headers.common['Authorization'])*/
      let responseApi = await instance.delete(process.env.API+endpoint);
        return responseApi
    } catch (error) {
      if (!navigator.onLine) {
        if (!isOffline) {
          isOffline = true
          Dialog.create({
              title:'',
              message: "Connection unstabil. Please check your connection",
              dark: true,
              persistent: true
          }).onDismiss(() => {
            isOffline = false
          })
        }
      }
      console.error(error);
    }
  }
  Vue.prototype.$apiGet = function(target) {return apiGet(target)}
  Vue.prototype.$apiPost = function(target,data) {return apiPost(target,data)}
  Vue.prototype.$apiDelete = function(target,data) {return apiDelete(target,data)}
}
