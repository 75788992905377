import { Cookies } from 'quasar'
export default {
    name: 'getTheme',
  data () {
    return {
      darkMode: true,
      theme: 'dark'
    }
  },
  beforeMount() {

    if (Cookies.has("theme")) {
      Cookies.remove("theme")
    }
    if (this.$route.query.theme === 'light') {
      this.theme = 'light'
      Cookies.set("theme", 'light')
    } else {
      this.theme = 'dark'
      Cookies.set("theme", 'dark')
    }
  },
  mounted(){

    let htmlElement = document.documentElement;
    let theme = this.$route.query.theme;

    if (theme === 'light') {
      htmlElement.classList.add('theme-light')
      htmlElement.classList.remove('theme-dark')
      // htmlElement.setAttribute('theme', 'light');
      this.darkMode = false
    } else {
      htmlElement.classList.add('theme-dark')
      htmlElement.classList.remove('theme-light')
      // htmlElement.setAttribute('theme', 'dark')
      this.darkMode = true
    }
  },
  watch: {
    darkMode: function () {
        // add/remove class to/from html tag
        let htmlElement = document.documentElement;

        if (this.darkMode) {
            // localStorage.setItem("theme", 'dark');
            htmlElement.classList.add('theme-dark')
            htmlElement.classList.remove('theme-light')
        } else {
            // localStorage.setItem("theme", 'light');
            htmlElement.classList.add('theme-light')
            htmlElement.classList.remove('theme-dark')
        }
    }
  },
  computed:{},
  methods:{},
}