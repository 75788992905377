import axios from 'axios'
import { Cookies } from 'quasar'

export default {
  state: {
    platform:null,
    meta_data:null,
    m_desc:'',
    m_image:'',
    browser:'',
    ios_device: ''
  },
  getters: {
    getPlatform(state){
      return state.platform
    },
    getMetaData(state){
      return state.meta_data;
    },
    getMdesc(state){
      return state.m_desc;
    },
    getMimage(state){
      return state.m_image;
    },
    getBrowser(state){
      return state.browser;
    },
    getIosDevice(state){
      return state.ios_device
    }
  },
  mutations: {
    SET_PLATFORM(state,payload){
      state.platform = payload;
    },
    SET_METADATA(state,payload){
      state.meta_data = payload;
    },
    SET_MDESC(state,payload){
      state.m_desc = payload;
    },
    SET_MIMAGE(state,payload){
      state.m_image = payload;
    },
    SET_BROWSER(state,payload){
      state.browser = payload;
    },
    SET_IOS_DEVICE(state,payload){
      state.ios_device = payload;
    },
  },
  actions: {
    platform({commit},payload){
      commit('SET_PLATFORM',payload)
    },
    meta_data({commit},payload){
      commit('SET_METADATA',payload)
    },
    set_browser({commit, getters},payload){
      commit('SET_BROWSER',payload)
    },
    set_ios_device({commit, getters},payload){
      commit('SET_IOS_DEVICE',payload)
    },
    async getData({commit,getters,dispatch},payload){
      if(process.env.SERVER){
        console.time('getData')
        try{
          if(payload.name == 'details' || payload.name == 'watch'){
            let get = axios.get(process.env.API+'/api/v1/webvisitor/'+ payload.id)
            .then(success => {
              if (success.data.content != null) {
                commit('SET_MDESC',success.data.content.synopsis)
                commit('SET_MIMAGE',success.data.content.image_url)
                console.timeEnd('getData')
                return 'done'
              }
            })
            .catch(error=>{
              console.log(error,'ini error get data');
              return 'error'
            })
            await get;
            return get
          }else{
            commit('SET_MDESC','Nonton dunia olahraga dan hiburan dari channel TV Nasional & TV Premium, serta puluhan ribu jam film, serial drama, sinetron, dan animasi dari berbagai genre dan negara.')
            commit('SET_MIMAGE',process.env.FE+'/statics/app-logo-128x128.png')
            console.timeEnd('getData')
          }
        }catch(error){
            console.log(error);
            console.timeEnd('getData')
        }
        
      }
    }
  }
}
