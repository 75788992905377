import {
  Cookies,
  Dialog,
  Loading,
  Platform
} from 'quasar'
import axios from 'axios'
import submitModal from '@/components/submitModal.vue'
import CryptoJS from 'crypto-js'

export default {
  state: {
    device_id: 'cGFydG5lcnMteGlhb21p',
    _tnap:null,
    webpSupported:'',
    temp_email:'',
    temp_password:'',
    uid:'',
    isPayTv:'no',
    user_photo:'',
    isLogin: null,
    userProfile: null,
    bundles:null,
    LoginWith:null,
  },
  getters: {
    getIsLogin (state) {
      return state.isLogin
    },
    getUid (state) {
      return state.uid
    },
    getProfiles (state) {
      return state.userProfile
    },
    getLoginWith (state) {
      return state.LoginWith
    }
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state._tnap = payload
    },
    SET_TEMP(state, payload) {
      state.temp_email = payload.email
      state.temp_password = payload.password
    },
    SET_ISLOGIN(state, payload) {
      state.isLogin = payload
    },
    SET_PAYTV(state, payload) {
      state.isPayTv = payload
    },
    SET_UID(state, payload) {
      state.uid = payload
    },
    SET_USERPROFILE(state,payload){
        state.userProfile = payload
    },
    SET_BUNDLES(state,payload){
        state.bundles = payload
    },
    SET_LOGINWITH(state,payload){
        state.LoginWith = payload
    }
  },
  actions: {
    set_token({
      commit
    }, payload) {
      commit('SET_TOKEN', payload)
    },
    set_auth({
      commit
    }, payload) {
      commit('SET_ISLOGIN', payload)
    },
    set_paytv({
      commit
    }, payload) {
      commit('SET_PAYTV', payload)
    },
    set_loginwith({
      commit
    }, payload) {
      commit('SET_LOGINWITH', payload)
    },
    set_bundles({
        commit
    }, payload) {
        commit('SET_BUNDLES', payload)
    },
    set_profile({
      commit
    },payload){
      commit('SET_USERPROFILE',payload);
    },
    profiles({
      commit,
      dispatch
    }) {
      this._vm.$apiGet('/api/v/profile/info')
        .then(success => {
          if (success.data.data != null) {
            Cookies.set('profile', JSON.stringify(success.data.data), {
              path: '/'
            });
            commit('SET_USERPROFILE', success.data.data);
            Cookies.set('bundles', JSON.stringify(success.data.subscriptions.content_bundle), {
              path: '/'
            })
            dispatch('set_bundles', success.data.subscriptions.content_bundle)

            if (Cookies.has('isLogin') && Cookies.get('isLogin') == true) {
              if (success.data.data.name.toLowerCase().indexOf('visitor') != -1) {
                Cookies.set('isLogin', false, {
                  path: '/'
                })
                dispatch('set_auth', false)
              }
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    register({
      commit
    }, payload) {
      // let dvId = ''
      // if (Cookies.has('device_id')) {
      //   dvId = Cookies.get('device_id')
      // } else {
      //   function uuidv4() {
      //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      //       var r = Math.random() * 16 | 0,
      //         v = c == 'x' ? r : (r & 0x3 | 0x8);
      //       return v.toString(16);
      //     });
      //   }
      //   Cookies.remove('device_id')
      //   Cookies.set('device_id', uuidv4());
      //   dvId = Cookies.get('device_id')
      // }
      let data = {
        "device_id": "cGFydG5lcnMteGlhb21p",
        "handphone": "",
        "password": payload.password,
        "platform": "webview",
        "username": payload.email
      }
      Loading.show()
      if (payload.password != payload.passwordConf) {
        Dialog.create({
          component: submitModal,
          text: 'Password and Password Confirmation are missmatch'
        })
        Loading.hide()
      } else {
        if (payload.password.length < 6) {
          Dialog.create({
            component: submitModal,
            text: 'Password must have at least 6 characters'
          })
          Loading.hide()
        } else if (payload.passwordConf.length < 6) {
          Dialog.create({
            component: submitModal,
            text: 'Password Confirmation must have at least 6 characters'
          })
          Loading.hide()
        } else {
          this._vm.$apiPost('/api/v/signup', data)
            .then(success => {
              Loading.hide()
              if (success.data.status.code == 27) {
                this.$router.push('/auth/verify');
                commit('SET_TEMP', payload)
              } else {
                Dialog.create({
                  component: submitModal,
                  text: success.data.status.message_client
                }).onOk(() => {})
              }
            })
            .catch(error => {
              console.log(error);
            })
        }
      }
    },
    resendmail({
      commit,
      state
    }) {
      let data = {
        "email": state.temp_email
      }
      Loading.show()
      this._vm.$apiPost('/api/v1/resendverification', data)
        .then(success => {
          Loading.hide()
          Dialog.create({
            component: submitModal,
            text: success.data.status.message_client
          }).onOk(() => {
            if (success.data.status.code == 27) {
              this.$router.push('/');
            }
          })
        })
        .catch(error => {
          console.log(error);
        })
    },
    login({
      commit,
      dispatch
    }, payload) {
      //   let dvId = ''
      // if (Cookies.has('device_id')) {
      //   dvId = Cookies.get('device_id')
      // } else {
      //   function uuidv4() {
      //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      //       var r = Math.random() * 16 | 0,
      //         v = c == 'x' ? r : (r & 0x3 | 0x8);
      //       return v.toString(16);
      //     });
      //   }
      //   Cookies.remove('device_id')
      //   Cookies.set('device_id', uuidv4());
      //   dvId = Cookies.get('device_id')
      // }
      let data = {
        "device_id": "cGFydG5lcnMteGlhb21p",
        "password": payload.password,
        "platform": "web",
        "username": payload.email
      }
      Loading.show()
      this._vm.$apiPost('/api/v/login', data)
        .then(success => {
          if (success.data.status.code != 0 && success.data.status.code != 18) {
            Dialog.create({
              component: submitModal,
              text: success.data.status.message_client
            })
          } else {
            if (Cookies.has('isLogin')) {
              Cookies.remove('isLogin')
            }
            Cookies.set('isLogin', true, {
              expires: 1,
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });

            commit('SET_ISLOGIN', true)
            if (Cookies.has('_tnap')) {
              Cookies.remove('_tnap')
            }
            function encryptData(params) {
              if (params) {
                return CryptoJS.AES.encrypt(params, process.env.KEYCRYP).toString() 
              } else {
                return null
              }
            }
            Cookies.set('_tnap', encryptData(success.data.data.access_token), {
              expires: 1,
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });
            commit('SET_TOKEN', encryptData(success.data.data.access_token));
            if (Cookies.has('uid')) {
              Cookies.remove('uid')
            }
            Cookies.set('uid', success.data.data.user.id, {
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });
            commit('SET_UID', success.data.data.user.id);
            commit('SET_PAYTV', success.data.data.payTvConnected ? 'yes' : 'no')
            Cookies.set('isPayTv', success.data.data.payTvConnected ? 'yes' : 'no', {
              path: '/'
            })
            Cookies.set('loginWith', 'm_login', {
              path: '/'
            })
            dispatch('set_loginwith', 'm_login')
            dispatch('profiles')
            
            if (Cookies.has('afterLoginMustGoTo')) {
              this.$router.replace(Cookies.get('afterLoginMustGoTo'));
            } else {
              this.$router.replace('/');
            }
            // if (Platform.is.mobile) {
            //   this.$router.replace('/');
            // } else {
            //   this.dispatch('close_login_modal', false);
            //   if (payload.path == '/auth/forgot-password') {
            //     this.$router.push('/');
            //   }
            // }
          }
          Cookies.remove('afterLoginMustGoTo')
          Loading.hide()
        })
        .catch(error => {
          Loading.hide()
          console.log(error);
        })
    },
    signOut({
      commit,
      dispatch
    }, payload) {
      Loading.show()
      // function uuidv4() {
      //   return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      //     var r = Math.random() * 16 | 0,
      //       v = c == 'x' ? r : (r & 0x3 | 0x8);
      //     return v.toString(16);
      //   });
      // }
      // if (Cookies.has('device_id')) {
      //   Cookies.remove('device_id')
      // }
      // Cookies.set('device_id', uuidv4());
      // let dvId = Cookies.get('device_id')

      let data = {
        "device_id": dvId,
        "password": "e707bf95318387fcf199fd66911fa07e",
        "platform": "web",
        "username": "c19425acc9d808160d5bdb3259888609@gmail.com"
      }
      axios.post(process.env.API + '/api/v/login', data)
        .then(success => {
          Loading.hide()
          function encryptData(params) {
            if (params) {
              return CryptoJS.AES.encrypt(params, process.env.KEYCRYP).toString() 
            } else {
              return null
            }
          }
          dispatch('set_token', encryptData(success.data.data.access_token))
          dispatch('set_auth', false)
          if (Cookies.has('_tnap')) {
            Cookies.remove('_tnap')
          }
          Cookies.set('_tnap', encryptData(success.data.data.access_token), {
            path: '/'
          });
          Cookies.set('isLogin', false, {
            path: '/'
          });
          dispatch('set_paytv', 'no')
          Cookies.set('isPayTv', 'no', {
            path: '/'
          })
          Cookies.remove('user_photo')
          Cookies.remove('profile')
          Cookies.remove('bundles')
          dispatch('set_bundles', null)
          if (Cookies.has('uid')) {
            Cookies.remove('uid')
          }
          Cookies.set('uid', success.data.data.user.id, {
            path: '/'
          });
          commit('SET_UID', success.data.data.user.id);
          this.$router.push('/');
        })
        .catch(error => {
          console.log(error)
        })
    },
    social_login({
      commit,
      dispatch
    }, payload) {
      let data = {
        "device_id": navigator.userAgent,
        "platform": "web",
      }
      if (payload.from == 'google') {
        data['id_token'] = payload.data.response.id_token
      } else {
        data.device_id = payload.data.accessToken.substring(0, 11);
        data['access_token'] = payload.data.accessToken
      }
      Loading.show()
      this._vm.$apiPost('/api/v4/identity/' + payload.from, data)
        .then(success => {
          if (success.data.status.code != 0 && success.data.status.code != 18) {
            Dialog.create({
              component: submitModal,
              text: success.data.status.message_client
            });
          } else {
            if (Cookies.has('isLogin')) {
              Cookies.remove('isLogin')
            }
            Cookies.set('isLogin', true, {
              expires: 1,
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });
            commit('SET_ISLOGIN', true)
            if (Cookies.has('_tnap')) {
              Cookies.remove('_tnap')
            }
            function encryptData(params) {
              if (params) {
                return CryptoJS.AES.encrypt(params, process.env.KEYCRYP).toString() 
              } else {
                return null
              }
            }
            Cookies.set('_tnap', encryptData(success.data.data.access_token), {
              expires: 1,
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });
            commit('SET_TOKEN', encryptData(success.data.data.access_token));
            if (Cookies.has('uid')) {
              Cookies.remove('uid')
            }
            Cookies.set('uid', success.data.data.user.id, {
              path: '/',
              // secure: true,
              // sameSite: 'Strict'
            });
            commit('SET_UID', success.data.data.user.id);
            commit('SET_PAYTV', success.data.data.payTvConnected ? 'yes' : 'no')
            Cookies.set('isPayTv', success.data.data.payTvConnected ? 'yes' : 'no', {
              path: '/'
            })
            Cookies.set('loginWith', 's_login', {
              path: '/'
            })
            dispatch('set_loginwith', 's_login')
            dispatch('profiles')

            if (Cookies.has('afterLoginMustGoTo')) {
              this.$router.replace(Cookies.get('afterLoginMustGoTo'));
            } else {
              this.$router.replace('/');
            }
            // if (Platform.is.mobile) {
            //   this.$router.replace('/');
            // } else {
            //   if (this.getters.getLoginModal) {
            //     this.dispatch('close_login_modal', false);
            //     if (payload.path == '/auth/forgot-password') {
            //       this.$router.push('/')
            //     }
            //   }
            //   if (this.getters.getRegisModal) {
            //     this.dispatch('close_regis_modal', false);
            //   }
            // }
            Cookies.set('user_photo', payload.from == 'facebook' ?
              dispatch('facebook_user_photo', 'https://graph.facebook.com/v4.0/' + payload.data.userID + '/picture?redirect=false') :
              payload.data.profile.Paa, {
                path: '/'
              })
          }
          Cookies.remove('afterLoginMustGoTo')
          Loading.hide()
        })
        .catch(error => {
          Loading.hide()
          console.log(error);
        })
    },
    facebook_user_photo({
      commit
    }, payload) {
      return axios.get(payload)
        .then(response => {
          console.log(response);
          return response.url;
        })
        .catch(error => {
          Loading.hide()
          console.log(error);
        })
    },
    forgotpassword({
      commit,
      dispatch
    }, payload) {
      let data = {
        "username": payload.email
      }
      Loading.show()
      this._vm.$apiPost('/api/v/forgot-password', data)
        .then(success => {
          Loading.hide()
          Dialog.create({
            component: submitModal,
            text: success.data.status.message_client
          }).onOk(() => {
            if (success.data.status.code == 27) {
              this.$router.push('/');
            }
          })
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
}
