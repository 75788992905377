export default {
  data() {
    return {
      description: '',
    }
  },
  meta() {
    return {
      title: 'Vision+ - ' + this.titleMeta(this.$route.name),
      meta: [{
          name: 'author',
          content: 'VisionPlus.id'
        },
        {
          name: 'viewport',
          content: 'initial-scale=1, maximum-scale=1'
        },
        {
          'property': 'og:title',
          'content': 'Vision+ - ' + this.titleMeta(this.$route.name),
          'vmid': 'og:title'
        },
        {
          'property': 'twitter:title',
          'content': 'Vision+ - ' + this.titleMeta(this.$route.name),
          'vmid': 'twitter:title'
        },
        {
          name: 'google-site-verification',
          content: 'MU1SEKOh4hqPEsvAbaq6jq517rVPPMu3oC3gVJGuu7U'
        },
        {
          'http-equiv': 'x-ua-compatible',
          content: 'text/html; charset=UTF-8'
        },
        {
          name: 'keywords',
          content: 'vision plus,visionplus, okezone,  mnc,  nonton, streaming,  gratis, ott,  live, handphone,  aplikasi, mnc vision, mnc play,channel,rcti,  gtv,  mnctv,  inews,  film, sinetron, series,ftv,drama korea,drakor,bola,sea games,filipina,  u19,  U20,olahraga, live match, top,tukang ojek pengkolan,  zpop dream indonesia 2019,  si doel,  indonesian idol,film indonesia, film hollywood, tvn,  film korea, the voice indonesia,  serial, catch up tv,putar ulang channel,  premium channel,  jepang, anime,  film thailand,  horror, comedy, film bollywood, thiller,  action, motogp, f1, film india,bein, sports,  film gratis,  preman pensiun, koki koki cilik,  mahasiswi baru, bulu tangkis, badminton,layar tancap, tv, hollywood,  futsal, thriller, pop corn, premier,layar kaca, indoxxi,  bioskop,  xxi,  21, cinema, cineplex'
        },
        {
          'property': 'og:type',
          'content': 'website'
        }
      ]
    }
  },
  methods: {
    titleMeta(name) {
      if (name) {
        return name == 'details' ?
          this.$route.params.type.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          }) + ' : ' + this.$route.params.name.split('-').join(' ').replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          }) :
          name == 'channel' ? 'Channel : ' + this.$route.params.title.split('-').join(' ').toUpperCase() :
          name == 'thematic details' ? 'Thematic : ' + this.$route.params.nameMovie.split('-').join(' ').replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          }) :
          name.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase()
          })
      } else {
        return name
      }

    },
  }
}
