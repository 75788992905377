import { render, staticRenderFns } from "./submitModal.vue?vue&type=template&id=3c76f528&"
import script from "./submitModal.vue?vue&type=script&lang=js&"
export * from "./submitModal.vue?vue&type=script&lang=js&"
import style0 from "./submitModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js"
import QDialog from 'quasar/src/components/dialog/QDialog.js'
import QCard from 'quasar/src/components/card/QCard.js'
import QCardSection from 'quasar/src/components/card/QCardSection.js'
import QIcon from 'quasar/src/components/icon/QIcon.js'
import QCardActions from 'quasar/src/components/card/QCardActions.js'
import QBtn from 'quasar/src/components/btn/QBtn.js'
qInstall(component, 'components', {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn})
