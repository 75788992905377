import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { Platform, Dialog, Cookies } from 'quasar'
Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({ store, ssrContext }) {
  const platform = process.env.SERVER ? Platform.parseSSR(ssrContext) : Platform;
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies;
  const isLogin = cookies.get('isLogin');
  store.dispatch('platform', platform.is.desktop ? 'desktop' : 'mobile')
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })
  Router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requireAuth)) {
      if (store.getters['getIsLogin']) {
        next()
      } else {
        next('/')
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if(isLogin){
        next('/')
      }else{
        next()
      }
    }else {
      let data;
      /*if(to.name == 'details'){
        data = {
          name:to.name,
          id:to.params.id
        }
        store.dispatch('getData',data)
        .then(success=>{
            if(success == 'done'){
              console.log('done')
              next();
            }else{
              console.log('error success')
              next();
            }
        })
        .catch(error=>{
            console.log(error);
            next();
        })
      }else{
        next();
      }*/
      next();
      /*if(currentRoute.params.id != 'css' && currentRoute.params.id != 'icons' && currentRoute.params.id != undefined){
        console.log(data);
        store.dispatch('getData',data)
      }*/

    }
  })

  return Router
}
