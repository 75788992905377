
const routes = [
  {
    path: '/',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      { 
        path: '',
        name:'home',
        component: () => import('pages/Index.vue')
      }
    ]
  },
  // {
  //   path: '/detail/:type/:id/:name/:subcategoryId',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'details',
  //       component: () => import('pages/detail.vue')
  //     },
  //   ]
  // },
  // {
  //   path: '/auth/register',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Register',
  //       component: () => import('pages/auth/register.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/auth/login',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Login',
  //       component: () => import('pages/auth/login.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/auth/verify',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Verify',
  //       component: () => import('pages/auth/verify.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/profile',
  //   component: () => import('layouts/ProfileLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Edit profile',
  //       component: () => import('pages/profile/profile.vue')
  //     },
  //     {
  //       path: 'setting',
  //       name:'Settings',
  //       component: () => import('pages/profile/setting.vue')
  //     },
  //     {
  //       path: 'package',
  //       name:'Package',
  //       component: () => import('pages/profile/package.vue')
  //     },
  //     {
  //       path: 'change-password',
  //       name:'Change password',
  //       component: () => import('pages/profile/changePassword.vue')
  //     },
  //   ]
  // },
  // {
  //   path: '/success-page',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Success',
  //       component: () => import('pages/successPage.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/forgot-password',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Forgot Password',
  //       component: () => import('pages/auth/forgotPassword.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/list/:type',
  //   component: () => import('layouts/EmptyLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'List',
  //       component: () => import('pages/listContent.vue')
  //     }
  //   ]
  // },
  {
    path: '/embed/vod/:id/:ccid',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        path: '',
        name:'Embed Vod',
        component: () => import('pages/embedVod.vue')
      }
    ]
  },
  {
    path: '/embed/live/:id',
    component: () => import('layouts/EmptyLayout.vue'),
    children: [
      {
        path: '',
        name:'Embed Live',
        component: () => import('pages/embedLive.vue')
      }
    ]
  },
  // {
  //   path: '/faq',
  //   component: () => import('layouts/RulesLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'FAQ',
  //       component: () => import('pages/faq.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/terms',
  //   component: () => import('layouts/RulesLayout.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name:'Syarat & Ketentuan',
  //       component: () => import('pages/tnc.vue')
  //     }
  //   ]
  // }
]

// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}

export default routes
