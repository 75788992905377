// import something here
import Vue from 'vue'

if (process.browser) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
  Vue.use(VueAwesomeSwiper)
}
// "async" is optional
export default async ({ /* app, router, Vue, ... */ }) => {
  // something to do
}
