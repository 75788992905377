// import something here
import Vue from "vue";

import { VLazyImagePlugin } from "v-lazy-image";

Vue.use(VLazyImagePlugin);
// "async" is optional
export default async ({ /* app, router, Vue, ... */ }) => {
  // something to do
}
